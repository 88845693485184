<template>
  <main class="round-post-page">
    <section>
      <div class="container py-5">
        <div class="row">
          <div class="md-down:col-12 lg:col-5 xl:col-3">
            <div class="sticky-top" style="top: 85px;">
              <mega-card class="card-round">
                <mega-drop-zone
                  class="rounded-0"
                  @change="renderFile"
                  :type="['image/jpeg', 'image/png']"
                >
                  <mega-image ratio="9x16" :src="preview" v-if="!preview" />
                  <img
                    class="w-100"
                    alt=""
                    style="transition: .1s"
                    :src="preview"
                    v-if="preview"
                    :class="{ 'opacity-0': rendering }"
                  />
                </mega-drop-zone>
              </mega-card>
            </div>
          </div>

          <div class="md-down:col-12 lg:col-7 xl:col-9">
            <mega-card title="Round Options">
              <div class="card-body">
                <mega-select
                  placeholder="Select User"
                  label="Select User"
                  filter
                  @search="search"
                  :options="users"
                  v-model="round.idt_user"
                />

                <mega-range
                  class="bg-primary mb-0"
                  label="Height (cm)"
                  v-model="round.height"
                  :min="10"
                  :max="3000"
                  :step="10"
                />
                <div
                  class="d-flex justify-content-between text-muted"
                  style="position: relative; top: -9px;"
                >
                  <small>10cm</small>
                  <small>3000cm</small>
                </div>
                <mega-number
                  :min="10"
                  :max="3000"
                  :step="10"
                  v-model="round.height"
                />

                <mega-textarea
                  label="Description"
                  v-model="round.message"
                  rows="3"
                />
              </div>
            </mega-card>

            <map-card
              ratio="16x9"
              @update="updatePosition"
              :lat="round.lat"
              :lng="round.lng"
            >
              <mega-switch
                class="mb-0 w-100"
                label="Hide location"
                v-model="round.is_location_hidden"
              />
              <small class="text-muted"
                >You can hide location from Public, but is still be in Round
                details</small
              >
            </map-card>
          </div>
        </div>

        <div class="navbar">
          <div class="navbar-group ml-auto">
            <mega-button
              class="btn-success"
              :loading="loading"
              :disabled="!valid"
              @click="submit"
              >Post Round</mega-button
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import MapCard from "../Round_Post/components/map";

export default {
  name: "RoundPostPhotoPage",
  props: ["roundId"],
  data() {
    return {
      users: [],
      models: [],
      loading: false,
      rendering: false,
      playVideo: false,
      preview: undefined,
      filter: {
        type: undefined,
        group: undefined
      },
      sliderOption: {
        spaceBetween: 30,
        slidesPerView: "auto"
      },
      round: {
        lat: 59.9598743,
        lng: 30.2965115,
        height: 100,
        message: "",
        idt_user: undefined,
        idc_round_type: 2,
        idt_model_modif: undefined,
        preview: undefined,
        image: undefined,
        is_location_hidden: false,
        place_name: "",
        place_address: "",
        profile: {}
      }
    };
  },
  computed: {
    video_link() {
      return this.round.media
        ? this.round.media["url"]
        : this.round.videos
        ? this.round.videos[0]["url"]
        : null;
    },
    valid() {
      let required = [
        "idc_round_type",
        "idt_user",
        "preview",
        "lat",
        "lng",
        "height"
      ];
      let valid = true;

      Object.keys(this.round).map(prop => {
        if (required.includes(prop) && this.round[prop] === undefined) {
          valid = false;
        }
      });

      return valid;
    }
  },
  mounted() {
    this.$navbar.name = "Make Photo Round";

    this.search();
  },
  methods: {
    search(search) {
      let params = {};

      if (search) params["search"] = search;

      this.$api.v1.get("/userList", { params }).then(response => {
        this.users = [];

        response.data.users.map(user => {
          this.users.push({
            value: user.idt_user,
            name: user.name + (user.username ? " (" + user.username + ")" : "")
          });
        });
      });
    },

    submit() {
      this.loading = true;

      let data = this.round;
      let formData = new FormData();

      delete data.profile;
      data["height"] = data["height"] * 0.01;
      data.sn_id = this.$store.getters.selectedSnId;

      Object.keys(data).map(prop => {
        if (this.round[prop] !== undefined)
          formData.append(prop, this.round[prop]);
      });

      this.$api.v2
        .post("/round", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(response => {
          this.loading = false;
          this.$router.push("/round/" + response.data["round"].idt_round);
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    video_toggle() {
      if (this.playVideo) this.$refs["video"].pause();
      else this.$refs["video"].play();

      this.playVideo = !this.playVideo;
    },

    updatePosition(location) {
      this.round.place_address = location.address;
      this.round.place_name = location.name;
      this.round.lat = location.lat;
      this.round.lng = location.lng;
    },

    renderFile(file) {
      this.round.image = file;
      this.round.preview = file;
      this.rendering = true;

      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setTimeout(() => {
          this.preview = reader.result;
        }, 100);
        setTimeout(() => {
          this.rendering = false;
        }, 200);
      };
    }
  },
  components: {
    MapCard
  }
};
</script>
